import firebase from 'firebase/compat/app'

// Add the Firebase products that you want to use
import 'firebase/compat/auth'
import {
  child,
  get,
  getDatabase,
  ref,
  set,
  remove,
  update
} from 'firebase/database'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID
}

firebase.initializeApp(firebaseConfig)
const db = getDatabase()

firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    localStorage.setItem('authUser', JSON.stringify(user))
  } else {
    localStorage.removeItem('authUser')
  }
})

/**
 * Handle the error
 * @param {*} error
 */
// eslint-disable-next-line no-underscore-dangle
const _handleError = (error) => {
  // var errorCode = error.code;
  const errorMessage = error.message
  return errorMessage
}

/**
 * Registers the user with given details
 */
const register = (email, password) => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(
        () => {
          resolve(firebase.auth().currentUser)
        },
        (error) => {
          reject(_handleError(error))
        }
      )
  })
}

/**
 * Registers the user with given details
 */
const editProfileAPI = (email, password) => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(
        () => {
          resolve(firebase.auth().currentUser)
        },
        (error) => {
          reject(_handleError(error))
        }
      )
  })
}

/**
 * Login user with given details
 */
const login = (email, password) => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(
        () => {
          resolve(firebase.auth().currentUser)
        },
        (error) => {
          reject(_handleError(error))
        }
      )
  })
}

/**
 * forget Password user with given details
 */
const forgetPassword = (email) => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .sendPasswordResetEmail(email, {
        url: `${window.location.protocol}//${window.location.host}/login`
      })
      .then(() => {
        resolve(true)
      })
      .catch((error) => {
        reject(_handleError(error))
      })
  })
}

/**
 * Logout the user
 */
const logout = () => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        resolve(true)
      })
      .catch((error) => {
        reject(_handleError(error))
      })
  })
}

const setLoggeedInUser = (user) => {
  localStorage.setItem('authUser', JSON.stringify(user))
}

/**
 * Returns the authenticated user
 */
const getAuthenticatedUser = () => {
  if (!localStorage.getItem('authUser')) return null
  return JSON.parse(localStorage.getItem('authUser'))
}

const getProducts = async (subCategory) => {
  const dbRef = ref(db)
  return get(child(dbRef, `subCategoryProducts/${subCategory}`))
    .then((snapshot) => (snapshot.exists() ? snapshot.val() : {}))
    .catch((error) => {
      console.error(error)
    })
}

const getMyProducts = async (subCategory) => {
  const user = getAuthenticatedUser()
  if (!user) {
    throw new Error('Not Authenticated')
  }
  const { uid } = user
  const dbRef = ref(db)
  return get(child(dbRef, `customerProducts/${uid}/${subCategory}`))
    .then((snapshot) => (snapshot.exists() ? snapshot.val() : {}))
    .catch((error) => {
      console.error(error)
    })
}

const getCategories = async () => {
  const dbRef = ref(db)
  return get(child(dbRef, `categories`))
    .then((snapshot) => (snapshot.exists() ? snapshot.val() : []))
    .catch((error) => {
      console.error(error)
    })
}

const addToCart = (cartItem) => {
  const user = getAuthenticatedUser()
  if (!user) {
    throw new Error('Not Authenticated')
  }
  const { uid } = user
  const dbRef = ref(db)
  const { id: productId, ...cartItemRest } = cartItem
  return set(child(dbRef, `cart/${uid}/${productId}`), {
    ...cartItemRest
  })
}

const removeCartItem = (productId) => {
  const user = getAuthenticatedUser()
  if (!user) {
    throw new Error('Not Authenticated')
  }
  const { uid } = user
  const dbRef = ref(db)
  return remove(child(dbRef, `cart/${uid}/${productId}`))
}

const updateCartItem = (cartItem) => {
  const user = getAuthenticatedUser()
  if (!user) {
    throw new Error('Not Authenticated')
  }
  const { uid } = user
  const dbRef = ref(db)
  const { id: productId, ...cartItemRest } = cartItem
  return update(child(dbRef, `cart/${uid}/${productId}`), {
    ...cartItemRest
  })
}

const getCartData = () => {}
const getOrders = () => []
const getShops = () => {}
const getProductDetail = () => {}
const addNewOrder = (order) => {
  const user = getAuthenticatedUser()
  if (!user) {
    throw new Error('Not Authenticated')
  }
  console.log(order)
}
const updateOrder = () => {}
const deleteOrder = () => {}

const getInvoices = () => {}
const getInvoiceDetail = () => {}

export {
  db,
  register,
  login,
  editProfileAPI,
  forgetPassword,
  logout,
  setLoggeedInUser,
  getAuthenticatedUser,
  getCartData,
  addToCart,
  removeCartItem,
  updateCartItem,
  getOrders,
  getProducts,
  getMyProducts,
  getCategories,
  getShops,
  getProductDetail,
  addNewOrder,
  updateOrder,
  deleteOrder,
  getInvoices,
  getInvoiceDetail
}
