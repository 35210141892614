import { call, put, takeEvery } from 'redux-saga/effects'

// Ecommerce Redux States
import {
  getCartData,
  getOrders,
  getCategories,
  getProducts,
  getMyProducts,
  getShops,
  getProductDetail,
  addNewOrder,
  updateOrder,
  deleteOrder,
  addToCart,
  removeCartItem,
  updateCartItem
} from 'utils/helpers/firebase_helper'
import {
  GET_CART_DATA,
  GET_ORDERS,
  GET_CATEGORIES,
  GET_PRODUCT_DETAIL,
  GET_PRODUCTS,
  GET_MY_PRODUCTS,
  GET_SHOPS,
  ADD_NEW_ORDER,
  ADD_TO_CART,
  UPDATE_ORDER,
  GET_ORDER_DETAIL,
  REMOVE_CART_ITEM,
  UPDATE_CART_ITEM
} from './actionTypes'
import {
  getCartDataFail,
  getCartDataSuccess,
  getOrdersFail,
  getOrdersSuccess,
  getOrderDetailFail,
  getOrderDetailSuccess,
  getCategoriesSuccess,
  getCategoriesFail,
  getProductDetailFail,
  getProductDetailSuccess,
  getProductsFail,
  getProductsSuccess,
  getMyProductsFail,
  getMyProductsSuccess,
  getShopsFail,
  getShopsSuccess,
  addOrderFail,
  addOrderSuccess,
  addToCartFail,
  updateOrderSuccess,
  updateOrderFail,
  removeCartItemFail,
  updateCartItemFail
} from './actions'

// Include Both Helper File with needed methods

function* fetchCategories() {
  try {
    const response = yield call(getCategories)
    yield put(getCategoriesSuccess(response))
  } catch (error) {
    yield put(getCategoriesFail(error))
  }
}

function* fetchProducts({ subCategory }) {
  try {
    const { products = {} } = yield call(getProducts, subCategory)
    yield put(getProductsSuccess(products))
  } catch (error) {
    yield put(getProductsFail(error))
  }
}

function* fetchMyProducts({ subCategory }) {
  try {
    const products = yield call(getMyProducts, subCategory)
    yield put(getMyProductsSuccess(products))
  } catch (error) {
    yield put(getMyProductsFail(error))
  }
}

function* fetchProductDetail({ productId }) {
  try {
    const response = yield call(getProductDetail, productId)
    yield put(getProductDetailSuccess(response))
  } catch (error) {
    yield put(getProductDetailFail(error))
  }
}

function* fetchOrders() {
  try {
    const response = yield call(getOrders)
    yield put(getOrdersSuccess(response))
  } catch (error) {
    yield put(getOrdersFail(error))
  }
}

function* fetchCartData() {
  try {
    const response = yield call(getCartData)
    yield put(getCartDataSuccess(response))
  } catch (error) {
    yield put(getCartDataFail(error))
  }
}

function* fetchShops() {
  try {
    const response = yield call(getShops)
    yield put(getShopsSuccess(response))
  } catch (error) {
    yield put(getShopsFail(error))
  }
}

function* onUpdateOrder({ payload: order }) {
  try {
    const response = yield call(updateOrder, order)
    yield put(updateOrderSuccess(response))
  } catch (error) {
    yield put(updateOrderFail(error))
  }
}

function* onGetOrderDetail({ payload: order }) {
  try {
    const response = yield call(deleteOrder, order)
    console.log('response', response)
    yield put(getOrderDetailSuccess(response))
  } catch (error) {
    console.log('error', error)
    yield put(getOrderDetailFail(error))
  }
}

function* onAddNewOrder({ payload: order }) {
  try {
    const response = yield call(addNewOrder, order)
    yield put(addOrderSuccess(response))
  } catch (error) {
    yield put(addOrderFail(error))
  }
}

function* onAddToCart({ payload: cartItem }) {
  try {
    yield call(addToCart, cartItem)
  } catch (error) {
    yield put(addToCartFail(error))
  }
}

function* onRemoveCartIem({ payload: productId }) {
  try {
    yield call(removeCartItem, productId)
  } catch (error) {
    yield put(removeCartItemFail(error))
  }
}

function* onUpdateCartIem({ payload: cartItem }) {
  try {
    yield call(updateCartItem, cartItem)
  } catch (error) {
    yield put(updateCartItemFail(error))
  }
}

function* ecommerceSaga() {
  yield takeEvery(GET_CATEGORIES, fetchCategories)
  yield takeEvery(GET_PRODUCTS, fetchProducts)
  yield takeEvery(GET_MY_PRODUCTS, fetchMyProducts)
  yield takeEvery(GET_PRODUCT_DETAIL, fetchProductDetail)
  yield takeEvery(GET_ORDERS, fetchOrders)
  yield takeEvery(GET_CART_DATA, fetchCartData)
  yield takeEvery(GET_SHOPS, fetchShops)
  yield takeEvery(ADD_TO_CART, onAddToCart)
  yield takeEvery(REMOVE_CART_ITEM, onRemoveCartIem)
  yield takeEvery(UPDATE_CART_ITEM, onUpdateCartIem)
  yield takeEvery(ADD_NEW_ORDER, onAddNewOrder)
  yield takeEvery(UPDATE_ORDER, onUpdateOrder)
  yield takeEvery(GET_ORDER_DETAIL, onGetOrderDetail)
}

export default ecommerceSaga
