import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import MetaTags from 'react-meta-tags'
import PropTypes from 'prop-types'
import { withRouter, Link } from 'react-router-dom'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider
} from 'react-bootstrap-table2-paginator'

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import * as moment from 'moment'

import { Card, CardBody, Col, Container, Row, Badge } from 'reactstrap'

import Breadcrumbs from 'components/Common/Breadcrumb'
import { getOrders } from 'store/actions'
import Loader from 'components/Loader'

const Orders = () => {
  const dispatch = useDispatch()

  const { orders } = useSelector((state) => ({
    orders: state.ecommerce.orders
  }))

  const pageOptions = {
    sizePerPage: 10,
    totalSize: (orders && orders.length) || 0, // replace later with size(orders),
    custom: true
  }
  const { SearchBar } = Search

  const toLowerCase1 = (str) => {
    return str === '' || str === undefined ? '' : str.toLowerCase()
  }

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format('DD MMM Y')
    return date1
  }

  const EcommerceOrderColumns = () => [
    {
      dataField: 'orderId',
      text: 'Order ID',
      sort: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      formatter: (cellContent, row) => (
        <Link to='#' className='text-body fw-bold'>
          {row.orderId}
        </Link>
      )
    },
    {
      dataField: 'billingName',
      text: 'Billing Name',
      sort: true
    },
    {
      dataField: 'orderdate',
      text: 'Date',
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.orderdate)
    },
    {
      dataField: 'total',
      text: 'Total',
      sort: true
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      formatter: (cellContent, row) => (
        <Badge
          className={`font-size-12 badge-soft-${row.badgeclass}`}
          color={row.badgeClass}
          pill
        >
          {row.paymentStatus}
        </Badge>
      )
    },
    {
      dataField: 'paymentMethod',
      isDummyField: true,
      text: 'Payment Method',
      sort: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      formatter: (cellContent, row) => (
        <>
          <i
            className={
              row.paymentMethod !== 'COD'
                ? `fab fa-cc-${toLowerCase1(row.paymentMethod)} me-1`
                : 'fab fas fa-money-bill-alt me-1'
            }
          />
          {row.paymentMethod}
        </>
      )
    },
    {
      dataField: 'view',
      isDummyField: true,
      text: 'View Details',
      sort: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      formatter: () => (
        <Link to='/order/id' className='btn btn-info btn-sm btn-rounded'>
          View Details
        </Link>
      )
    }
  ]

  useEffect(() => {
    dispatch(getOrders())
  }, [dispatch])

  return (
    <div className='page-content'>
      <MetaTags>
        <title>Orders | Suplyd - F&amp;B One Stop Shop</title>
      </MetaTags>
      <Container fluid>
        <Breadcrumbs title='Customer' breadcrumbItem='Orders' />
        <Row>
          <Col xs='12'>
            {orders ? (
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField='id'
                    columns={EcommerceOrderColumns()}
                    data={orders}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField='id'
                        data={orders}
                        columns={EcommerceOrderColumns()}
                        bootstrap4
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className='mb-2'>
                              <Col sm='4'>
                                <div className='search-box me-2 mb-2 d-inline-block'>
                                  <div className='position-relative'>
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className='bx bx-search-alt search-icon' />
                                  </div>
                                </div>
                              </Col>
                              <Col sm='8'>
                                <div className='text-sm-end'>
                                  <Link
                                    to='/my-list'
                                    className='btn btn-primary btn-rounded mb-2 me-2'
                                  >
                                    <i className='mdi mdi-plus me-1' />
                                    Make New Order
                                  </Link>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl='12'>
                                <div className='table-responsive'>
                                  <BootstrapTable
                                    keyField='id'
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={[
                                      {
                                        dataField: 'orderId',
                                        order: 'desc'
                                      }
                                    ]}
                                    selectRow={{
                                      mode: 'checkbox'
                                    }}
                                    classes='table align-middle table-nowrap table-check'
                                    headerWrapperClasses='table-light'
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className='align-items-md-center mt-30'>
                              <Col className='pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination'>
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            ) : (
              <Loader />
            )}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

Orders.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
  onAddNewOrder: PropTypes.func,
  onDeleteOrder: PropTypes.func,
  onUpdateOrder: PropTypes.func
}

export default withRouter(Orders)
