import { takeEvery, fork, put, all, call } from 'redux-saga/effects'

import { register } from 'utils/helpers/firebase_helper'

// Account Redux states
import { REGISTER_USER } from './actionTypes'
import { registerUserSuccessful, registerUserFailed } from './actions'

// Include Both Helper File with needed methods

// initialize relavant method of both Auth

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user } }) {
  try {
    const response = yield call(register, user.email, user.password)
    yield put(registerUserSuccessful(response))
  } catch (error) {
    yield put(registerUserFailed(error))
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga
