import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from 'reactstrap'
import CartList from 'components/CartList'
import Loader from 'components/Loader'
import SimpleBar from 'simplebar-react'
import toastr from 'toastr'
import { useList } from 'react-firebase-hooks/database'
import { useSelector } from 'react-redux'
import { ref } from 'firebase/database'

import { withTranslation } from 'react-i18next'
import { db } from '../../utils/helpers/firebase_helper'

const CartDropdown = ({ t }) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const { user } = useSelector((state) => ({
    user: state.Login.user
  }))
  const { uid } = user
  const [snapshots, loading, error] = useList(ref(db, `cart/${uid}`))

  if (error) {
    toastr.error(error, 'Failed!')
  }

  return (
    <Dropdown
      isOpen={menu}
      toggle={() => setMenu(!menu)}
      className='dropdown d-inline-block'
      tag='li'
    >
      <DropdownToggle
        className='btn header-item noti-icon'
        tag='button'
        id='page-header-notifications-dropdown'
      >
        <i className='bx bx-cart' />
        {snapshots && snapshots.length > 0 && (
          <span className='badge bg-danger rounded-pill'>
            {snapshots.length}
          </span>
        )}
      </DropdownToggle>

      <DropdownMenu className='dropdown-menu dropdown-menu-lg dropdown-menu-end p-0'>
        <div className='p-3'>
          <Row className='align-items-center'>
            <Col>
              <h6 className='m-0'> {t('Cart')} </h6>
            </Col>
            <div className='col-auto'>
              <a href='/cart' className='small'>
                {' '}
                View All
              </a>
            </div>
          </Row>
        </div>

        <SimpleBar style={{ height: '230px' }}>
          {(loading && <Loader />) || (
            <CartList
              products={snapshots.map((snap) => ({
                id: snap.key,
                ...snap.val()
              }))}
            />
          )}
        </SimpleBar>
        <div className='p-2 border-top d-grid'>
          <Link
            className='btn btn-sm btn-link font-size-14 text-center'
            to='/checkout'
          >
            <i className='mdi mdi-arrow-right-circle me-1' />{' '}
            <span key='t-view-more'>{t('Checkout')}</span>
          </Link>
        </div>
      </DropdownMenu>
    </Dropdown>
  )
}

export default withTranslation()(CartDropdown)

CartDropdown.propTypes = {
  t: PropTypes.func.isRequired
}
