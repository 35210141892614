import {
  GET_CART_DATA,
  GET_CART_DATA_FAIL,
  GET_CART_DATA_SUCCESS,
  GET_ORDERS,
  GET_ORDERS_FAIL,
  GET_ORDERS_SUCCESS,
  GET_ORDER_DETAIL,
  GET_ORDER_DETAIL_FAIL,
  GET_ORDER_DETAIL_SUCCESS,
  ADD_NEW_ORDER,
  ADD_ORDER_SUCCESS,
  ADD_ORDER_FAIL,
  ADD_TO_CART,
  ADD_TO_CART_FAIL,
  REMOVE_CART_ITEM,
  REMOVE_CART_ITEM_FAIL,
  UPDATE_CART_ITEM,
  UPDATE_CART_ITEM_FAIL,
  UPDATE_ORDER,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_FAIL,
  GET_PRODUCTS,
  GET_PRODUCTS_FAIL,
  GET_PRODUCTS_SUCCESS,
  GET_MY_PRODUCTS,
  GET_MY_PRODUCTS_FAIL,
  GET_MY_PRODUCTS_SUCCESS,
  GET_CATEGORIES,
  GET_CATEGORIES_FAIL,
  GET_CATEGORIES_SUCCESS,
  GET_SHOPS,
  GET_SHOPS_FAIL,
  GET_SHOPS_SUCCESS,
  GET_PRODUCT_DETAIL,
  GET_PRODUCT_DETAIL_FAIL,
  GET_PRODUCT_DETAIL_SUCCESS
} from './actionTypes'

export const getCategories = () => ({
  type: GET_CATEGORIES
})

export const getCategoriesSuccess = (categories) => ({
  type: GET_CATEGORIES_SUCCESS,
  payload: categories
})

export const getCategoriesFail = (error) => ({
  type: GET_CATEGORIES_FAIL,
  payload: error
})
export const getProducts = (subCategory) => ({
  type: GET_PRODUCTS,
  subCategory
})

export const getProductsSuccess = (products) => ({
  type: GET_PRODUCTS_SUCCESS,
  payload: products
})

export const getProductsFail = (error) => ({
  type: GET_PRODUCTS_FAIL,
  payload: error
})

export const getMyProducts = (subCategory) => ({
  type: GET_MY_PRODUCTS,
  subCategory
})

export const getMyProductsSuccess = (products) => ({
  type: GET_MY_PRODUCTS_SUCCESS,
  payload: products
})

export const getMyProductsFail = (error) => ({
  type: GET_MY_PRODUCTS_FAIL,
  payload: error
})

export const getProductDetail = (productId) => ({
  type: GET_PRODUCT_DETAIL,
  productId
})

export const getProductDetailSuccess = (products) => ({
  type: GET_PRODUCT_DETAIL_SUCCESS,
  payload: products
})

export const getProductDetailFail = (error) => ({
  type: GET_PRODUCT_DETAIL_FAIL,
  payload: error
})

export const getOrders = () => ({
  type: GET_ORDERS
})

export const getOrdersSuccess = (orders) => ({
  type: GET_ORDERS_SUCCESS,
  payload: orders
})

export const getOrdersFail = (error) => ({
  type: GET_ORDERS_FAIL,
  payload: error
})

export const addNewOrder = (order) => ({
  type: ADD_NEW_ORDER,
  payload: order
})

export const addOrderSuccess = (order) => ({
  type: ADD_ORDER_SUCCESS,
  payload: order
})

export const addOrderFail = (error) => ({
  type: ADD_ORDER_FAIL,
  payload: error
})

export const addToCart = (cartItem) => ({
  type: ADD_TO_CART,
  payload: cartItem
})

export const addToCartFail = (error) => ({
  type: ADD_TO_CART_FAIL,
  payload: error
})

export const removeCartItem = (productId) => ({
  type: REMOVE_CART_ITEM,
  payload: productId
})

export const removeCartItemFail = (error) => ({
  type: REMOVE_CART_ITEM_FAIL,
  payload: error
})

export const updateCartItem = (cartItem) => ({
  type: UPDATE_CART_ITEM,
  payload: cartItem
})

export const updateCartItemFail = (error) => ({
  type: UPDATE_CART_ITEM_FAIL,
  payload: error
})

export const updateOrder = (order) => ({
  type: UPDATE_ORDER,
  payload: order
})

export const updateOrderSuccess = (order) => ({
  type: UPDATE_ORDER_SUCCESS,
  payload: order
})

export const updateOrderFail = (error) => ({
  type: UPDATE_ORDER_FAIL,
  payload: error
})

export const getOrderDetail = (order) => ({
  type: GET_ORDER_DETAIL,
  payload: order
})

export const getOrderDetailSuccess = (order) => ({
  type: GET_ORDER_DETAIL_SUCCESS,
  payload: order
})

export const getOrderDetailFail = (error) => ({
  type: GET_ORDER_DETAIL_FAIL,
  payload: error
})

export const getCartData = () => ({
  type: GET_CART_DATA
})

export const getCartDataSuccess = (cartData) => ({
  type: GET_CART_DATA_SUCCESS,
  payload: cartData
})

export const getCartDataFail = (error) => ({
  type: GET_CART_DATA_FAIL,
  payload: error
})

export const getShops = () => ({
  type: GET_SHOPS
})

export const getShopsSuccess = (shops) => ({
  type: GET_SHOPS_SUCCESS,
  payload: shops
})

export const getShopsFail = (error) => ({
  type: GET_SHOPS_FAIL,
  payload: error
})
