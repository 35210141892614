/* CATEGORIES */
export const GET_CATEGORIES = 'GET_CATEGORIES'
export const GET_CATEGORIES_FAIL = 'GET_CATEGORIES_FAIL'
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS'

/* PRODUCTS */
export const GET_PRODUCTS = 'GET_PRODUCTS'
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS'
export const GET_PRODUCTS_FAIL = 'GET_PRODUCTS_FAIL'

/* PRODUCTS */
export const GET_MY_PRODUCTS = 'GET_MY_PRODUCTS'
export const GET_MY_PRODUCTS_SUCCESS = 'GET_MY_PRODUCTS_SUCCESS'
export const GET_MY_PRODUCTS_FAIL = 'GET_MY_PRODUCTS_FAIL'

/* PRODUCT DETAILS */
export const GET_PRODUCT_DETAIL = 'GET_PRODUCT_DETAIL'
export const GET_PRODUCT_DETAIL_SUCCESS = 'GET_PRODUCT_DETAIL_SUCCESS'
export const GET_PRODUCT_DETAIL_FAIL = 'GET_PRODUCT_DETAIL_FAIL'

/* CART */
export const ADD_TO_CART = 'ADD_TO_CART'
export const ADD_TO_CART_FAIL = 'ADD_TO_CART_FAIL'
export const REMOVE_CART_ITEM = 'REMOVE_CART_ITEM'
export const REMOVE_CART_ITEM_FAIL = 'REMOVE_CART_ITEM_FAIL'
export const UPDATE_CART_ITEM = 'UPDATE_CART_ITEM'
export const UPDATE_CART_ITEM_FAIL = 'UPDATE_CART_ITEM_FAIL'

/* ORDERS */
export const GET_ORDERS = 'GET_ORDERS'
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS'
export const GET_ORDERS_FAIL = 'GET_ORDERS_FAIL'

/* ORDER DETAIL */
export const GET_ORDER_DETAIL = 'GET_ORDERS_DETAIL'
export const GET_ORDER_DETAIL_SUCCESS = 'GET_ORDERS_DETAIL_SUCCESS'
export const GET_ORDER_DETAIL_FAIL = 'GET_ORDERS_DETAIL_FAIL'

/**
 * add ORDER
 */
export const ADD_NEW_ORDER = 'ADD_NEW_ORDER'
export const ADD_ORDER_SUCCESS = 'ADD_ORDER_SUCCESS'
export const ADD_ORDER_FAIL = 'ADD_ORDER_FAIL'

/**
 * Edit ORDER
 */
export const UPDATE_ORDER = 'UPDATE_ORDER'
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS'
export const UPDATE_ORDER_FAIL = 'UPDATE_ORDER_FAIL'

/* CART DATA */
export const GET_CART_DATA = 'GET_CART_DATA'
export const GET_CART_DATA_SUCCESS = 'GET_CART_DATA_SUCCESS'
export const GET_CART_DATA_FAIL = 'GET_CART_DATA_FAIL'

/* SHOPS */
export const GET_SHOPS = 'GET_SHOPS'
export const GET_SHOPS_SUCCESS = 'GET_SHOPS_SUCCESS'
export const GET_SHOPS_FAIL = 'GET_SHOPS_FAIL'
