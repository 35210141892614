import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags'
import {
  Row,
  Col,
  Alert,
  Container,
  Input,
  Label,
  Form,
  FormFeedback
} from 'reactstrap'

// Formik Validation
import * as Yup from 'yup'
import { useFormik } from 'formik'

// Social Media Imports
import { GoogleLogin } from 'react-google-login'
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

// Import config
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import logodark from 'assets/images/logo-dark.png'
import logolight from 'assets/images/logo-light.png'
import { facebook, google } from '../../config'

// action
import { registerUser, apiError, socialLogin } from '../../store/actions'

// redux

// import images
import CarouselPage from './CarouselPage'

const Register = ({ history }) => {
  const dispatch = useDispatch()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      username: '',
      password: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Please Enter Your Email'),
      username: Yup.string().required('Please Enter Your Username'),
      password: Yup.string().required('Please Enter Your Password')
    }),
    onSubmit: (values) => {
      dispatch(registerUser(values))
    }
  })

  const { user, registrationError } = useSelector((state) => ({
    user: state.Account.user,
    registrationError: state.Account.registrationError
  }))

  const signIn = (res, type) => {
    if (type === 'google' && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId
      }
      dispatch(socialLogin(postData, history, type))
    } else if (type === 'facebook' && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId
      }
      dispatch(socialLogin(postData, history, type))
    }
  }

  // handleGoogleLoginResponse
  const googleResponse = (response) => {
    signIn(response, 'google')
  }

  // handleFacebookLoginResponse
  const facebookResponse = (response) => {
    signIn(response, 'facebook')
  }

  useEffect(() => {
    dispatch(apiError(''))
  }, [])

  return (
    <div>
      <MetaTags>
        <title>Register | Suplyd - F&B One Stop Shop</title>
      </MetaTags>
      <Container fluid className='p-0'>
        <Row className='g-0'>
          <CarouselPage />

          <Col xl={3}>
            <div className='auth-full-page-content p-md-5 p-4'>
              <div className='w-100'>
                <div className='d-flex flex-column h-100'>
                  <div className='mb-4 mb-md-5'>
                    <Link to='dashboard' className='d-block auth-logo'>
                      <img
                        src={logodark}
                        alt=''
                        height='18'
                        className='auth-logo-dark'
                      />
                      <img
                        src={logolight}
                        alt=''
                        height='18'
                        className='auth-logo-light'
                      />
                    </Link>
                  </div>
                  <div className='my-auto'>
                    <div>
                      <h5 className='text-primary'>Register account</h5>
                      <p className='text-muted'>
                        Get your free Suplyd account now.
                      </p>
                    </div>

                    <div className='mt-4'>
                      <Form
                        className='form-horizontal'
                        onSubmit={(e) => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                      >
                        {user && user ? (
                          <Alert color='success'>
                            Register User Successfully
                          </Alert>
                        ) : null}

                        {registrationError && registrationError ? (
                          <Alert color='danger'>{registrationError}</Alert>
                        ) : null}

                        <div className='mb-3'>
                          <Label className='form-label'>Email</Label>
                          <Input
                            id='email'
                            name='email'
                            className='form-control'
                            placeholder='Enter email'
                            type='email'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ''}
                            invalid={
                              !!(
                                validation.touched.email &&
                                validation.errors.email
                              )
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type='invalid'>
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className='mb-3'>
                          <Label className='form-label'>Username</Label>
                          <Input
                            name='username'
                            type='text'
                            placeholder='Enter username'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.username || ''}
                            invalid={
                              !!(
                                validation.touched.username &&
                                validation.errors.username
                              )
                            }
                          />
                          {validation.touched.username &&
                          validation.errors.username ? (
                            <FormFeedback type='invalid'>
                              {validation.errors.username}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className='mb-3'>
                          <Label className='form-label'>Password</Label>
                          <Input
                            name='password'
                            type='password'
                            placeholder='Enter Password'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ''}
                            invalid={
                              !!(
                                validation.touched.password &&
                                validation.errors.password
                              )
                            }
                          />
                          {validation.touched.password &&
                          validation.errors.password ? (
                            <FormFeedback type='invalid'>
                              {validation.errors.password}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div>
                          <p className='mb-0'>
                            By registering you agree to the Suplyd{' '}
                            <a href='#' className='text-primary'>
                              Terms of Use
                            </a>
                          </p>
                        </div>

                        <div className='mt-4'>
                          <button
                            className='btn btn-primary btn-block '
                            type='submit'
                          >
                            Register
                          </button>
                        </div>
                      </Form>

                      <div className='mt-4 text-center'>
                        <h5 className='font-size-14 mb-3'>Sign in with</h5>

                        <ul className='list-inline'>
                          <li className='list-inline-item'>
                            <FacebookLogin
                              appId={facebook.APP_ID}
                              autoLoad={false}
                              callback={facebookResponse}
                              render={(renderProps) => (
                                <Link
                                  to='#'
                                  className='social-list-item bg-info text-white border-info'
                                  onClick={renderProps.onClick}
                                >
                                  <i className='mdi mdi-facebook' />
                                </Link>
                              )}
                            />
                          </li>
                          <li className='list-inline-item'>
                            <GoogleLogin
                              clientId={google.CLIENT_ID}
                              render={(renderProps) => (
                                <Link
                                  to='#'
                                  className='social-list-item bg-danger text-white border-danger'
                                  onClick={renderProps.onClick}
                                >
                                  <i className='mdi mdi-google' />
                                </Link>
                              )}
                              onSuccess={googleResponse}
                              onFailure={() => {}}
                            />
                          </li>
                        </ul>
                      </div>

                      <div className='mt-5 text-center'>
                        <p>
                          Already have an account?
                          <Link
                            to='login'
                            className='font-weight-medium text-primary'
                          >
                            {' '}
                            Login
                          </Link>{' '}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className='mt-4 mt-md-5 text-center'>
                    <p className='mb-0'>© {new Date().getFullYear()} Suplyd.</p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Register

Register.propTypes = {
  history: PropTypes.object
}
