import React from 'react'
import PropTypes from 'prop-types'
import ReactApexChart from 'react-apexcharts'

const StackedColumnChart = ({ periodData }) => {
  const options = {
    chart: {
      stacked: !0,
      toolbar: {
        show: 1
      },
      zoom: {
        enabled: !0
      }
    },
    plotOptions: {
      bar: {
        horizontal: !1,
        columnWidth: '15%'
        // endingShape: "rounded"
      }
    },
    dataLabels: {
      enabled: !1
    },
    xaxis: {
      show: true,
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ],
      labels: {
        show: true
      }
    },
    colors: ['#556ee6', '#f1b44c', '#34c38f'],
    legend: {
      position: 'bottom'
    },
    fill: {
      opacity: 1
    }
  }
  return (
    <ReactApexChart
      options={options}
      series={[...periodData]}
      type='bar'
      height='359'
      className='apex-charts'
    />
  )
}

StackedColumnChart.propTypes = {
  periodData: PropTypes.object
}
export default StackedColumnChart
