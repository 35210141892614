import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Input } from 'reactstrap'
import { Link } from 'react-router-dom'
import { removeCartItem, updateCartItem } from 'store/actions'
import { roundWith2Decimal } from 'utils/helpers/dataModifiers'

// Import Breadcrumb

const CartItem = ({ product }) => {
  const dispatch = useDispatch()

  const handleRemoveCartItem = () => {
    dispatch(removeCartItem(product.id))
  }

  const handleUpdateQuantity = (action) => {
    const { quantity, id } = product
    const newQuantity = action === 'increment' ? quantity + 1 : quantity - 1
    if (newQuantity < 1) {
      handleRemoveCartItem()
    } else {
      dispatch(updateCartItem({ id, quantity: newQuantity }))
    }
  }

  return (
    <tr key={product.id}>
      <td>
        <h5 className='font-size-14 text-truncate'>
          <Link to={`/product-detail/${product.id}`} className='text-dark'>
            {product.name}
          </Link>
        </h5>
        <p className='mb-0'>
          <span className='fw-medium'>{product.color}</span>
        </p>
      </td>
      <td>EGP {roundWith2Decimal(product.price)}</td>
      <td>
        <div style={{ width: '100px' }}>
          <div className='input-group'>
            <div className='input-group-prepend'>
              <button
                type='button'
                className='btn btn-outline-primary btn-sm'
                onClick={() => {
                  handleUpdateQuantity('decrement')
                }}
              >
                -
              </button>
            </div>
            <Input
              type='text'
              className='form-control-sm text-center'
              value={product.quantity}
              placeholder='0'
              readOnly
            />
            <div className='input-group-append'>
              <button
                type='button'
                className='btn btn-primary btn-sm'
                onClick={() => {
                  handleUpdateQuantity('increment')
                }}
              >
                +
              </button>
            </div>
          </div>
        </div>
      </td>
      <td>EGP {roundWith2Decimal(product.quantity * product.price)}</td>
      <td>
        <Link
          to='#'
          onClick={() => handleRemoveCartItem()}
          className='action-icon text-danger'
        >
          <i className='mdi mdi-trash-can font-size-18' />
        </Link>
      </td>
    </tr>
  )
}

CartItem.propTypes = {
  product: PropTypes.object.isRequired
}
export default CartItem
