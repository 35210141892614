import {
  GET_CART_DATA_FAIL,
  GET_CART_DATA_SUCCESS,
  GET_ORDERS_FAIL,
  GET_ORDERS_SUCCESS,
  GET_ORDER_DETAIL_SUCCESS,
  GET_ORDER_DETAIL_FAIL,
  GET_CATEGORIES_FAIL,
  GET_CATEGORIES_SUCCESS,
  GET_PRODUCTS_FAIL,
  GET_PRODUCTS_SUCCESS,
  GET_MY_PRODUCTS_FAIL,
  GET_MY_PRODUCTS_SUCCESS,
  GET_SHOPS_FAIL,
  GET_SHOPS_SUCCESS,
  GET_PRODUCT_DETAIL_SUCCESS,
  GET_PRODUCT_DETAIL_FAIL,
  ADD_ORDER_SUCCESS,
  ADD_ORDER_FAIL,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_FAIL
} from './actionTypes'

const INIT_STATE = {
  categories: {},
  products: {},
  myProducts: {},
  product: {},
  orders: [],
  cartData: {},
  shops: [],
  error: {}
}

const Ecommerce = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload
      }

    case GET_CATEGORIES_FAIL:
      return {
        ...state,
        error: action.payload
      }

    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload
      }

    case GET_PRODUCTS_FAIL:
      return {
        ...state,
        error: action.payload
      }

    case GET_MY_PRODUCTS_SUCCESS:
      return {
        ...state,
        myProducts: action.payload
      }

    case GET_MY_PRODUCTS_FAIL:
      return {
        ...state,
        error: action.payload
      }

    case GET_PRODUCT_DETAIL_SUCCESS:
      return {
        ...state,
        product: action.payload
      }

    case GET_PRODUCT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload
      }

    case GET_ORDERS_SUCCESS:
      return {
        ...state,
        orders: action.payload
      }

    case GET_ORDERS_FAIL:
      return {
        ...state,
        error: action.payload
      }

    case ADD_ORDER_SUCCESS:
      return {
        ...state,
        orders: [...state.orders, action.payload]
      }

    case ADD_ORDER_FAIL:
      return {
        ...state,
        error: action.payload
      }

    case UPDATE_ORDER_SUCCESS:
      return {
        ...state,
        orders: state.orders.map((order) =>
          order.id === action.payload.id.toString()
            ? { order, ...action.payload }
            : order
        )
      }

    case UPDATE_ORDER_FAIL:
      return {
        ...state,
        error: action.payload
      }

    case GET_ORDER_DETAIL_SUCCESS:
      return {
        ...state,
        orderDetail: action.payload
      }

    case GET_ORDER_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload
      }

    case GET_CART_DATA_SUCCESS:
      return {
        ...state,
        cartData: action.payload
      }

    case GET_CART_DATA_FAIL:
      return {
        ...state,
        error: action.payload
      }

    case GET_SHOPS_SUCCESS:
      return {
        ...state,
        shops: action.payload
      }

    case GET_SHOPS_FAIL:
      return {
        ...state,
        error: action.payload
      }

    default:
      return state
  }
}

export default Ecommerce
