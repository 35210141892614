import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getCategories, getMyProducts } from 'store/actions'

import { Swiper, SwiperSlide } from 'swiper/react'
import Loader from 'components/Loader'

import classnames from 'classnames'

// install Swiper modules

const FilterBar = () => {
  const dispatch = useDispatch()

  const { categories } = useSelector((state) => ({
    categories: state.ecommerce.categories
  }))

  const [activeMaterialGroupKey, setActiveMaterialGroupKey] =
    useState('food_and_beverage')
  const [activeCategoryKey, setActiveCategoryKey] = useState('bakery')
  const [activeSubCategoryKey, setActiveSubCategoryKey] = useState('biscuits')

  const handleMaterialGroupClick = (materialGroupKey) => {
    const firstCategoryKey = Object.keys(
      categories[materialGroupKey].categories
    ).shift()

    const firstSubCategoryKey = Object.keys(
      categories[materialGroupKey].categories[firstCategoryKey].subCategories ||
        {}
    ).shift()

    setActiveMaterialGroupKey(materialGroupKey)
    setActiveCategoryKey(firstCategoryKey)
    setActiveSubCategoryKey(firstSubCategoryKey || null)
  }

  const handleCategoryClick = (activeMaterialGroup, categoryKey) => {
    const firstSubCategoryKey = Object.keys(
      activeMaterialGroup.categories[categoryKey].subCategories || {}
    ).shift()

    setActiveCategoryKey(categoryKey)
    setActiveSubCategoryKey(firstSubCategoryKey || null)
  }

  useEffect(() => {
    dispatch(getCategories())
  }, [dispatch])

  useEffect(() => {
    dispatch(getMyProducts(activeSubCategoryKey))
  }, [dispatch, activeSubCategoryKey])

  if (Object.keys(categories).length === 0) {
    return <Loader />
  }

  const materialGroupKeys = Object.keys(categories)
  const activeMaterialGroup = categories[activeMaterialGroupKey]
  const activeCategory = activeMaterialGroup.categories[activeCategoryKey] || {}

  return (
    <div>
      <h5 className='font-size-14 mb-3'>Categories</h5>
      {materialGroupKeys.map((materialGroupKey) => (
        <button
          key={`_materialGroup_${materialGroupKey}_`}
          type='button'
          className={classnames('btn btn-rounded me-1', {
            'btn-outline-primary disabled':
              materialGroupKey === activeMaterialGroupKey,
            'btn-light': materialGroupKey !== activeMaterialGroupKey
          })}
          onClick={() => handleMaterialGroupClick(materialGroupKey)}
        >
          {categories[materialGroupKey].name}
        </button>
      ))}
      <div className='mt-2 mb-3'>
        <Swiper
          slidesPerView={4}
          spaceBetween={5}
          slidesPerGroup={1}
          autoHeight
          centeredSlides={false}
          breakpoints={{
            640: {
              slidesPerView: 6,
              spaceBetween: 5
            },
            768: {
              slidesPerView: 8,
              spaceBetween: 10
            },
            1024: {
              slidesPerView: 8,
              spaceBetween: 10
            },
            1400: {
              slidesPerView: 10,
              spaceBetween: 10
            },
            1600: {
              slidesPerView: 12,
              spaceBetween: 10
            }
          }}
          className='mySwiper'
        >
          {Object.keys(activeMaterialGroup.categories).map((categoryKey) => (
            <SwiperSlide
              key={`_category_${categoryKey}`}
              className='d-flex align-items-stretch h-100'
            >
              <div
                className={classnames(
                  'd-flex flex-column align-items-center justify-content-center py-2 px-1 mb-0 w-100 ',
                  {
                    'text-primary': categoryKey === activeCategoryKey
                  }
                )}
                onClick={() =>
                  handleCategoryClick(activeMaterialGroup, categoryKey)
                }
                aria-hidden
              >
                <img
                  src={activeMaterialGroup.categories[categoryKey].image}
                  alt={activeMaterialGroup.categories[categoryKey].name}
                  height={50}
                />
                <p
                  className='text-center mb-0 pe-none'
                  tabIndex='-1'
                  aria-disabled='true'
                >
                  {activeMaterialGroup.categories[categoryKey].name}
                </p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className='mb-3'>
        <Swiper
          slidesPerView={6}
          spaceBetween={2}
          slidesPerGroup={1}
          centeredSlides={false}
          breakpoints={{
            640: {
              slidesPerView: 6,
              spaceBetween: 2
            },
            768: {
              slidesPerView: 8,
              spaceBetween: 3
            },
            1024: {
              slidesPerView: 10,
              spaceBetween: 3
            },
            1400: {
              slidesPerView: 12,
              spaceBetween: 3
            }
          }}
          className='mySwiper'
        >
          {activeCategory.subCategories &&
            Object.keys(activeCategory.subCategories).map((subCategoryKey) => (
              <SwiperSlide
                key={`_subCategory_${subCategoryKey}`}
                className='d-flex flex-column'
              >
                <button
                  type='button'
                  className={classnames('btn btn-rounded btn-sm', {
                    'btn-outline-primary disabled':
                      subCategoryKey === activeSubCategoryKey,
                    'btn-light': subCategoryKey !== activeSubCategoryKey
                  })}
                  onClick={() => setActiveSubCategoryKey(subCategoryKey)}
                >
                  {activeCategory.subCategories[subCategoryKey].name}
                </button>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </div>
  )
}

export default FilterBar
