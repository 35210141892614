import React from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Card, CardBody, Col } from 'reactstrap'
import { addToCart } from 'store/actions'

const ProductItem = ({ product }) => {
  const dispatch = useDispatch()

  /**
   * TODO: increment by extra quantifyStep || 1 if item is already in cart
   * This would require getting the cart quantity for this specific item
   */
  const handleAddtoCartClick = () => {
    const cartItem = {
      ...product,
      quantity: product.quantityStep || 1
    }
    dispatch(addToCart(cartItem))
  }

  /**
   * TODO: update "Add To Cart" button status if item is in cart already to be "Remove From Cart"
   */

  return (
    <Col xl='3' xxl='2' sm='6'>
      <Card>
        <CardBody>
          {product.image && (
            <div className='product-img position-relative'>
              <img
                src={product.image}
                alt=''
                className='img-fluid mx-auto d-block'
              />
            </div>
          )}
          <div className='mt-2'>
            <h5 className='mb-2 text-truncate'>
              <Link to={`/product-detail/${product.id}`} className='text-dark'>
                {product.name}
              </Link>
            </h5>
            <p className='mb-0'>{product.brand || 'N/A'}</p>
          </div>
          <div className='mt-2'>
            <h6 className='mb-0'>
              <b>{`${product.currency} ${product.price}/${product.container}`}</b>
            </h6>
            <p className='mb-0'>
              {`${product.container} of ${product.measurementValue}${product.measurementUnit}`}
            </p>
          </div>
          <div className='mt-2 d-flex flex-column'>
            <button
              type='button'
              className='btn btn-block btn-outline-primary'
              onClick={() => handleAddtoCartClick()}
            >
              <i className='bx bx-cart me-2' /> Add to cart
            </button>
          </div>
        </CardBody>
      </Card>
    </Col>
  )
}

ProductItem.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string,
    minOrderQuantity: PropTypes.number,
    quantityStep: PropTypes.number,
    image: PropTypes.string,
    name: PropTypes.string,
    brand: PropTypes.string,
    container: PropTypes.string,
    measurementValue: PropTypes.number,
    measurementUnit: PropTypes.string,
    currency: PropTypes.string,
    price: PropTypes.number
  }).isRequired
}

export default ProductItem
