import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'reactstrap'
import CheckoutListItem from './CheckoutListItem'

const CheckoutList = ({ products }) => {
  const grandTotal = products.reduce(
    (acc, curr) => acc + curr.quantity * curr.price,
    0
  )
  return (
    <div className='table-responsive'>
      <Table className='table align-middle mb-0 table-nowrap'>
        <thead className='table-light'>
          <tr>
            <th scope='col'>Product</th>
            <th scope='col'>Product Desc</th>
            <th scope='col'>Price</th>
          </tr>
        </thead>
        <tbody>
          {products.map((p) => (
            <CheckoutListItem product={p} key={`_orderItem_${p.id}`} />
          ))}
          <tr>
            <td colSpan='2'>
              <h6 className='m-0 text-end'>Sub Total:</h6>
            </td>
            <td>EGP {grandTotal}</td>
          </tr>
          <tr>
            <td colSpan='3'>
              <div className='bg-primary bg-soft p-3 rounded'>
                <h5 className='font-size-14 text-primary mb-0'>
                  <i className='fas fa-shipping-fast me-2' /> Shipping
                  <span className='float-end'>Free</span>
                </h5>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan='2'>
              <h6 className='m-0 text-end'>Total:</h6>
            </td>
            <td>EGP {grandTotal}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}

CheckoutList.propTypes = {
  products: PropTypes.array.isRequired
}

export default CheckoutList
