import React, { useState } from 'react'
import MetaTags from 'react-meta-tags'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row
} from 'reactstrap'
import classnames from 'classnames'

import Breadcrumbs from 'components/Common/Breadcrumb'
import FiltersBar from 'components/Products/FiltersBar'
import ProductItem from 'components/Products/ProductItem'
import { useSelector } from 'react-redux'
import Loader from 'components/Loader'
import Search from 'components/Search'

const MyList = ({ history }) => {
  const { products } = useSelector((state) => ({
    products: state.ecommerce.myProducts
  }))

  const productKeys = Object.keys(products)

  const [activeTab, setActiveTab] = useState('1')

  const [page, setPage] = useState(1)
  const totalPage = 5

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const handlePageClick = (selectedPage) => {
    setPage(selectedPage)
  }

  return (
    <div className='page-content'>
      <MetaTags>
        <title>Products | Suplyd - F&amp;B One Stop Shop</title>
      </MetaTags>
      <Container fluid>
        <Breadcrumbs title='Customer' breadcrumbItem='Products List' />
        <Row>
          <FiltersBar />
        </Row>
        <Row className='mb-3'>
          <Col lg='6'>
            <div className='mt-4 mt-sm-0 d-flex align-items-center'>
              <Search />
              <Nav className='product-view-nav' pills>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === '1'
                    })}
                    onClick={() => {
                      toggleTab('1')
                    }}
                  >
                    <i className='bx bx-grid-alt' />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === '2'
                    })}
                    onClick={() => {
                      toggleTab('2')
                    }}
                  >
                    <i className='bx bx-list-ul' />
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </Col>
          <Col lg='6'>
            <div className='mt-4 mt-sm-0 float-sm-end d-flex align-items-center'>
              <button
                className='btn btn-success btn-rounded me-2'
                type='button'
                onClick={() => history.push('/cart')}
              >
                <i className='bx bx-shopping-bag font-size-16' /> Make Order
              </button>
            </div>
          </Col>
        </Row>

        <Row>
          {products ? (
            productKeys.length > 0 &&
            productKeys.map((productKey) => (
              <ProductItem
                key={`_col_${productKey}`}
                product={{ id: productKey, ...products[productKey] }}
              />
            ))
          ) : (
            <Loader />
          )}
        </Row>

        <Row>
          <Col lg='12'>
            <Pagination className='pagination pagination-rounded justify-content-end mb-2'>
              <PaginationItem disabled={page === 1}>
                <PaginationLink
                  previous
                  href='#'
                  onClick={() => handlePageClick(page - 1)}
                />
              </PaginationItem>
              {Array(totalPage).map((item, i) => (
                <PaginationItem active={i + 1 === page} key={i}>
                  <PaginationLink
                    onClick={() => handlePageClick(i + 1)}
                    href='#'
                  >
                    {i + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}
              <PaginationItem disabled={page === totalPage}>
                <PaginationLink
                  next
                  href='#'
                  onClick={() => handlePageClick(page + 1)}
                />
              </PaginationItem>
            </Pagination>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

MyList.propTypes = {
  products: PropTypes.array,
  history: PropTypes.object,
  onGetProducts: PropTypes.func
}

export default withRouter(MyList)
