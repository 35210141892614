import React from 'react'
import { Redirect } from 'react-router-dom'

// Profile
import UserProfile from 'pages/Authentication/user-profile'

// //Ecommerce Pages
import MyList from 'pages/MyList'
import ProductDetail from 'pages/Products/ProductDetail'
import Orders from 'pages/Orders/index'
import Cart from 'pages/Cart'
import Checkout from 'pages/Checkout'

// Authentication related pages
import Login from 'pages/Authentication/Login'
import Logout from 'pages/Authentication/Logout'
import Register from 'pages/Authentication/Register'
import ForgetPwd from 'pages/Authentication/ForgetPassword'

// Dashboard
import Dashboard from 'pages/Dashboard/index'
import OrderDetail from 'pages/Orders/OrderDetail'

const authProtectedRoutes = [
  { path: '/dashboard', component: Dashboard },

  // //profile
  { path: '/profile', component: UserProfile },

  // Ecommerce
  { path: '/products/:id', component: ProductDetail },
  { path: '/my-list', component: MyList },
  { path: '/orders', component: Orders },
  { path: '/orders/:id', component: OrderDetail },
  { path: '/cart', component: Cart },
  { path: '/checkout', component: Checkout },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: '/', exact: true, component: () => <Redirect to='/dashboard' /> }
]

const publicRoutes = [
  { path: '/logout', component: Logout },
  { path: '/login', component: Login },
  { path: '/forgot-password', component: ForgetPwd },
  { path: '/register', component: Register }
]

export { authProtectedRoutes, publicRoutes }
