import product1 from './img-1.png'
import product2 from './img-2.png'
import product3 from './img-3.png'
import product4 from './img-4.png'
import product5 from './Pro-8.png'
import product6 from './img-6.png'
import product7 from './Pro-1a.png'
import product8 from './Pro-1b.png'
import product9 from './Pro-2b.png'
import product10 from './Pro-4a.png'
import product11 from './Pro-12b.png'
import product12 from './Pro-8b.png'
import product13 from './Pro-17.png'

export {
  product1,
  product2,
  product3,
  product4,
  product5,
  product6,
  product7,
  product8,
  product9,
  product10,
  product11,
  product12,
  product13
}

export const productImages = {
  product1,
  product2,
  product3,
  product4,
  product5,
  product6,
  product7,
  product8,
  product9,
  product10,
  product11,
  product12,
  product13
}
