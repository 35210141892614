import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { logoutUser } from '../../store/actions'

// redux

const Logout = ({ history }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(logoutUser(history))
  }, [dispatch])

  return null
}

Logout.propTypes = {
  history: PropTypes.object
}

export default withRouter(Logout)
