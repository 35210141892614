import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import MetaTags from 'react-meta-tags'
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table
} from 'reactstrap'
import { Link } from 'react-router-dom'

import classNames from 'classnames'

// import Charts
import { withTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import Breadcrumbs from 'components/Common/Breadcrumb'
import modalimage1 from 'assets/images/product/img-7.png'
import modalimage2 from 'assets/images/product/img-4.png'
import StackedColumnChart from './StackedColumnChart'

// import action
import { getChartsData as onGetChartsData } from '../../store/actions'

// Pages Components
import WelcomeComp from './WelcomeComp'
import MonthlyEarning from './MonthlyEarning'
import SocialSource from './SocialSource'
import ActivityComp from './ActivityComp'
import TopCities from './TopCities'

// Import Breadcrumb

// i18n

// redux

const Dashboard = ({ t }) => {
  const dispatch = useDispatch()
  const [modal, setmodal] = useState(false)
  const [subscribemodal, setSubscribemodal] = useState(false)

  const { chartsData } = useSelector((state) => ({
    chartsData: state.Dashboard.chartsData
  }))

  const reports = [
    { title: 'Orders', iconClass: 'bx-copy-alt', description: '1,235' },
    { title: 'Revenue', iconClass: 'bx-archive-in', description: '$35, 723' },
    {
      title: 'Average Price',
      iconClass: 'bx-purchase-tag-alt',
      description: '$16.2'
    }
  ]

  // Turn on for first visit only
  // useEffect(() => {
  //   setTimeout(() => {
  //     setSubscribemodal(true)
  //   }, 2000)
  // }, [])

  const [periodData, setPeriodData] = useState([])
  const [periodType, setPeriodType] = useState('yearly')

  useEffect(() => {
    setPeriodData(chartsData)
  }, [chartsData])

  const onChangeChartPeriod = (pType) => {
    setPeriodType(pType)
    dispatch(onGetChartsData(pType))
  }

  useEffect(() => {
    dispatch(onGetChartsData('yearly'))
  }, [dispatch])

  return (
    <React.Fragment>
      <div className='page-content'>
        <MetaTags>
          <title>Dashboard | Suplyd - F&B One Stop Shop</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title='Suplyd' breadcrumbItem={t('Dashboard')} />

          <Row>
            <Col xl='4'>
              <WelcomeComp />
              <MonthlyEarning />
            </Col>
            <Col xl='8'>
              <Row>
                {/* Reports Render */}
                {reports.map((report, key) => (
                  <Col md='4' key={`_col_${key}`}>
                    <Card className='mini-stats-wid'>
                      <CardBody>
                        <div className='d-flex'>
                          <div className='flex-grow-1'>
                            <p className='text-muted fw-medium'>
                              {report.title}
                            </p>
                            <h4 className='mb-0'>{report.description}</h4>
                          </div>
                          <div className='avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon'>
                            <span className='avatar-title rounded-circle bg-primary'>
                              <i
                                className={`bx ${report.iconClass} font-size-24`}
                              />
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>

              <Card>
                <CardBody>
                  <div className='d-sm-flex flex-wrap'>
                    <h4 className='card-title mb-4'>Orders Made</h4>
                    <div className='ms-auto'>
                      <ul className='nav nav-pills'>
                        <li className='nav-item'>
                          <Link
                            to='#'
                            className={classNames(
                              { active: periodType === 'weekly' },
                              'nav-link'
                            )}
                            onClick={() => {
                              onChangeChartPeriod('weekly')
                            }}
                            id='one_month'
                          >
                            Week
                          </Link>{' '}
                        </li>
                        <li className='nav-item'>
                          <Link
                            to='#'
                            className={classNames(
                              { active: periodType === 'monthly' },
                              'nav-link'
                            )}
                            onClick={() => {
                              onChangeChartPeriod('monthly')
                            }}
                            id='one_month'
                          >
                            Month
                          </Link>
                        </li>
                        <li className='nav-item'>
                          <Link
                            to='#'
                            className={classNames(
                              { active: periodType === 'yearly' },
                              'nav-link'
                            )}
                            onClick={() => {
                              onChangeChartPeriod('yearly')
                            }}
                            id='one_month'
                          >
                            Year
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <div className="clearfix"></div> */}
                  <StackedColumnChart periodData={periodData} />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl='4'>
              <SocialSource />
            </Col>
            <Col xl='4'>
              <ActivityComp />
            </Col>

            <Col xl='4'>
              <TopCities />
            </Col>
          </Row>

          <Row>
            <Col lg='12'>{/* <LatestTranaction /> */}</Col>
          </Row>
        </Container>
      </div>

      {/* subscribe ModalHeader */}
      <Modal
        isOpen={subscribemodal}
        role='dialog'
        autoFocus
        centered
        data-toggle='modal'
        toggle={() => {
          setSubscribemodal(!subscribemodal)
        }}
      >
        <div>
          <ModalHeader
            className='border-bottom-0'
            toggle={() => {
              setSubscribemodal(!subscribemodal)
            }}
          />
        </div>
        <div className='modal-body'>
          <div className='text-center mb-4'>
            <div className='avatar-md mx-auto mb-4'>
              <div className='avatar-title bg-light  rounded-circle text-primary h1'>
                <i className='mdi mdi-email-open' />
              </div>
            </div>

            <div className='row justify-content-center'>
              <div className='col-xl-10'>
                <h4 className='text-primary'>Subscribe !</h4>
                <p className='text-muted font-size-14 mb-4'>
                  Subscribe our newletter and get notification to stay update.
                </p>

                <div className='input-group rounded bg-light'>
                  <Input
                    type='email'
                    className='form-control bg-transparent border-0'
                    placeholder='Enter Email address'
                  />
                  <Button color='primary' type='button' id='button-addon2'>
                    <i className='bx bxs-paper-plane' />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={modal}
        role='dialog'
        autoFocus
        centered
        className='exampleModal'
        tabIndex='-1'
        toggle={() => {
          setmodal(!modal)
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setmodal(!modal)
            }}
          >
            Order Details
          </ModalHeader>
          <ModalBody>
            <p className='mb-2'>
              Product id: <span className='text-primary'>#SK2540</span>
            </p>
            <p className='mb-4'>
              Billing Name: <span className='text-primary'>Neal Matthews</span>
            </p>

            <div className='table-responsive'>
              <Table className='table table-centered table-nowrap'>
                <thead>
                  <tr>
                    <th scope='col'>Product</th>
                    <th scope='col'>Product Name</th>
                    <th scope='col'>Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope='row'>
                      <div>
                        <img src={modalimage1} alt='' className='avatar-sm' />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className='text-truncate font-size-14'>
                          Wireless Headphone (Black)
                        </h5>
                        <p className='text-muted mb-0'>$ 225 x 1</p>
                      </div>
                    </td>
                    <td>$ 255</td>
                  </tr>
                  <tr>
                    <th scope='row'>
                      <div>
                        <img src={modalimage2} alt='' className='avatar-sm' />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className='text-truncate font-size-14'>
                          Hoodie (Blue)
                        </h5>
                        <p className='text-muted mb-0'>$ 145 x 1</p>
                      </div>
                    </td>
                    <td>$ 145</td>
                  </tr>
                  <tr>
                    <td colSpan='2'>
                      <h6 className='m-0 text-end'>Sub Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                  <tr>
                    <td colSpan='2'>
                      <h6 className='m-0 text-end'>Shipping:</h6>
                    </td>
                    <td>Free</td>
                  </tr>
                  <tr>
                    <td colSpan='2'>
                      <h6 className='m-0 text-end'>Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type='button'
              color='secondary'
              onClick={() => {
                setmodal(!modal)
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.func.isRequired
}

export default withTranslation()(Dashboard)
