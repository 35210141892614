import React from 'react'
import PropTypes from 'prop-types'
import { Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'

const SideNav = ({ activeTab, onTabSelect }) => {
  return (
    <Nav className='flex-column' pills>
      <NavItem>
        <NavLink
          className={classnames({ active: activeTab === '1' })}
          onClick={() => {
            onTabSelect('1')
          }}
        >
          <i className='bx bxs-truck d-block check-nav-icon mt-4 mb-2' />
          <p className='font-weight-bold mb-4'>Shipping Info</p>
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={classnames({ active: activeTab === '2' })}
          onClick={() => {
            onTabSelect('2')
          }}
        >
          <i className='bx bx-money d-block check-nav-icon mt-4 mb-2' />
          <p className='font-weight-bold mb-4'>Payment Info</p>
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={classnames({ active: activeTab === '3' })}
          onClick={() => {
            onTabSelect('3')
          }}
        >
          <i className='bx bx-badge-check d-block check-nav-icon mt-4 mb-2' />
          <p className='font-weight-bold mb-4'>Confirmation</p>
        </NavLink>
      </NavItem>
    </Nav>
  )
}

SideNav.propTypes = {
  activeTab: PropTypes.string.isRequired,
  onTabSelect: PropTypes.func.isRequired
}

export default SideNav
