import React from 'react'
import ReactApexChart from 'react-apexcharts'
import './dashboard.scss'

const ApexRadial = () => {
  const series = [67]
  const options = {
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        dataLabels: {
          name: {
            fontSize: '13px',
            color: 0,
            offsetY: 60
          },
          value: {
            offsetY: 22,
            fontSize: '16px',
            color: 0,
            formatter(e) {
              return `${e}%`
            }
          }
        }
      }
    },
    colors: ['#556ee6'],
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        shadeIntensity: 0.15,
        inverseColors: !1,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 65, 91]
      }
    },
    stroke: {
      dashArray: 4
    },
    labels: ['Series A']
  }
  return (
    <ReactApexChart
      options={options}
      series={series}
      type='radialBar'
      height='200'
      className='apex-charts'
    />
  )
}

export default ApexRadial
