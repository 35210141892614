import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

const NonAuthLayout = ({ children }) => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}

NonAuthLayout.propTypes = {
  children: PropTypes.node.isRequired
}

export default withRouter(NonAuthLayout)
