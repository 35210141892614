import PropTypes from 'prop-types'
import React from 'react'

import { Switch, BrowserRouter as Router } from 'react-router-dom'
import { connect } from 'react-redux'

// Import Routes all
import { authProtectedRoutes, publicRoutes } from './routes'

// Import all middleware
import Authmiddleware from './routes/route'

// layouts Format
import VerticalLayout from './layouts/VerticalLayout'
import NonAuthLayout from './layouts/GuestLayout'

// Import scss
import './assets/scss/theme.scss'

const App = () => {
  return (
    <Router>
      <Switch>
        {publicRoutes.map((route, idx) => (
          <Authmiddleware
            path={route.path}
            layout={NonAuthLayout}
            component={route.component}
            key={idx}
            isAuthProtected={false}
            exact
          />
        ))}

        {authProtectedRoutes.map((route, idx) => (
          <Authmiddleware
            path={route.path}
            layout={VerticalLayout}
            component={route.component}
            key={idx}
            isAuthProtected
            exact
          />
        ))}
      </Switch>
    </Router>
  )
}

App.propTypes = {
  layout: PropTypes.object
}

const mapStateToProps = (state) => {
  return {
    layout: state.Layout
  }
}

export default connect(mapStateToProps, null)(App)
