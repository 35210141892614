import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags'
import React from 'react'

import {
  Row,
  Col,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label
} from 'reactstrap'

// redux
import { useSelector, useDispatch } from 'react-redux'

import { Link } from 'react-router-dom'

// Formik validation
import * as Yup from 'yup'
import { useFormik } from 'formik'

// Social Media Imports
import { GoogleLogin } from 'react-google-login'
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

// actions

// import images
import logodark from 'assets/images/logo-dark.png'
import logolight from 'assets/images/logo-light.png'
import { loginUser, socialLogin } from '../../store/actions'

// Import config
import { facebook, google } from '../../config'

// import images
import CarouselPage from './CarouselPage'

const Login = ({ history }) => {
  const dispatch = useDispatch()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Please Enter Your Email'),
      password: Yup.string().required('Please Enter Your Password')
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, history))
    }
  })

  const { error } = useSelector((state) => ({
    error: state.Login.error
  }))

  const signIn = (res, type) => {
    if (type === 'google' && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId
      }
      dispatch(socialLogin(postData, history, type))
    } else if (type === 'facebook' && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId
      }
      dispatch(socialLogin(postData, history, type))
    }
  }

  // handleGoogleLoginResponse
  const googleResponse = (response) => {
    signIn(response, 'google')
  }

  // handleFacebookLoginResponse
  const facebookResponse = (response) => {
    signIn(response, 'facebook')
  }
  return (
    <div>
      <MetaTags>
        <title>Login | Suplyd</title>
      </MetaTags>
      <Container fluid className='p-0'>
        <Row className='g-0'>
          <CarouselPage />
          <Col xl={3}>
            <div className='auth-full-page-content p-md-5 p-4'>
              <div className='w-100'>
                <div className='d-flex flex-column h-100'>
                  <div className='mb-4 mb-md-5'>
                    <Link to='/dashboard' className='d-block auth-logo'>
                      <img
                        src={logodark}
                        alt=''
                        height='18'
                        className='auth-logo-dark'
                      />
                      <img
                        src={logolight}
                        alt=''
                        height='18'
                        className='auth-logo-light'
                      />
                    </Link>
                  </div>
                  <div className='my-auto'>
                    <div>
                      <h5 className='text-primary'>Welcome Back !</h5>
                      <p className='text-muted'>
                        Sign in to continue to Suplyd.
                      </p>
                    </div>

                    <div className='mt-4'>
                      <Form
                        className='form-horizontal'
                        onSubmit={(e) => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                      >
                        {error ? <Alert color='danger'>{error}</Alert> : null}

                        <div className='mb-3'>
                          <Label className='form-label'>Email</Label>
                          <Input
                            name='email'
                            className='form-control'
                            placeholder='Enter email'
                            type='email'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ''}
                            invalid={
                              !!(
                                validation.touched.email &&
                                validation.errors.email
                              )
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type='invalid'>
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className='mb-3'>
                          <Label className='form-label'>Password</Label>
                          <Input
                            name='password'
                            value={validation.values.password || ''}
                            type='password'
                            placeholder='Enter Password'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              !!(
                                validation.touched.password &&
                                validation.errors.password
                              )
                            }
                          />
                          {validation.touched.password &&
                          validation.errors.password ? (
                            <FormFeedback type='invalid'>
                              {validation.errors.password}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className='form-check'>
                          <Input
                            type='checkbox'
                            className='form-check-input'
                            id='auth-remember-check'
                          />
                          <label
                            className='form-check-label'
                            htmlFor='auth-remember-check'
                          >
                            Remember me
                          </label>
                        </div>

                        <div className='mt-3 d-grid'>
                          <button
                            className='btn btn-primary btn-block '
                            type='submit'
                          >
                            Log In
                          </button>
                        </div>
                      </Form>

                      <div className='mt-4 text-center'>
                        <Link to='/forgot-password' className='text-muted'>
                          <i className='mdi mdi-lock me-1' />
                          Forgot your password?
                        </Link>
                      </div>
                      <div className='mt-5 text-center'>
                        <p>
                          Don&apos;t have an account?
                          <Link
                            href='https://register.suplyd.app/'
                            target='_blank'
                            className='fw-medium text-primary'
                          >
                            {' '}
                            Signup now
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className='mt-4 mt-md-5 text-center'>
                    <p className='mb-0'>© {new Date().getFullYear()} Suplyd.</p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Login

Login.propTypes = {
  history: PropTypes.object
}
