import { call, put, takeEvery } from 'redux-saga/effects'
import { logout, login } from 'utils/helpers/firebase_helper'

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from './actionTypes'
import { apiError, loginSuccess, logoutUserSuccess } from './actions'

// Include Both Helper File with needed methods

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(login, user.email, user.password)
    yield put(loginSuccess(response))

    history.push('/dashboard')
  } catch (error) {
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem('authUser')

    if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
      const response = yield call(logout)
      yield put(logoutUserSuccess(response))
    }
    history.push('/login')
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
