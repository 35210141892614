import React from 'react'
import { Form, Input } from 'reactstrap'

const Search = () => {
  return (
    <Form>
      <div className='search-box me-2'>
        <div className='position-relative'>
          <Input
            type='text'
            className='form-control border-0'
            placeholder='Search...'
          />
          <i className='bx bx-search-alt search-icon' />
        </div>
      </div>
    </Form>
  )
}

export default Search
