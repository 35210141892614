import React from 'react'
import MetaTags from 'react-meta-tags'
import { useList } from 'react-firebase-hooks/database'
import { useSelector } from 'react-redux'
import { ref } from 'firebase/database'
import { db } from 'utils/helpers/firebase_helper'

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  CardTitle
} from 'reactstrap'
import { Link } from 'react-router-dom'

// Import Breadcrumb
import Breadcrumbs from 'components/Common/Breadcrumb'
import CartList from 'components/CartList'
import Loader from 'components/Loader'

const EcommerceCart = () => {
  const { user } = useSelector((state) => ({
    user: state.Login.user
  }))

  const { uid } = user
  const [snapshots, loading, error] = useList(ref(db, `cart/${uid}`))
  const discount = 1.0
  const grandTotal = snapshots.reduce(
    (acc, curr) => acc + curr.val().quantity * curr.val().price,
    0
  )

  console.log(error)

  if (loading) {
    return <Loader />
  }

  return (
    <div className='page-content'>
      <MetaTags>
        <title>Cart | Suplyd - F&B One Stop Shop</title>
      </MetaTags>
      <Container fluid>
        <Breadcrumbs title='Customer' breadcrumbItem='Cart' />
        <Row>
          <Col lx='8'>
            <Card>
              <CardBody>
                <CartList
                  products={snapshots.map((snap) => ({
                    id: snap.key,
                    ...snap.val()
                  }))}
                />

                <Row className='mt-4'>
                  <Col sm='6'>
                    <Link to='/my-list' className='btn btn-secondary'>
                      <i className='mdi mdi-arrow-left me-1' /> Continue
                      Shopping
                    </Link>
                  </Col>
                  <Col sm='6'>
                    <div className='text-sm-end mt-2 mt-sm-0'>
                      <Link to='/checkout' className='btn btn-success'>
                        <i className='mdi mdi-cart-arrow-right me-1' /> Checkout
                      </Link>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xl='4'>
            <Card>
              <CardBody>
                <CardTitle className='mb-3'>Order Summary</CardTitle>

                <div className='table-responsive'>
                  <Table className='table mb-0'>
                    <tbody>
                      <tr>
                        <td>Grand Total :</td>
                        <td>EGP {grandTotal}</td>
                      </tr>
                      <tr>
                        <td>Discount : </td>
                        <td>- EGP 0</td>
                      </tr>
                      <tr>
                        <td>Shipping Charge :</td>
                        <td>EGP 0</td>
                      </tr>
                      <tr>
                        <td>Estimated Tax : </td>
                        <td>EGP 0</td>
                      </tr>
                      <tr>
                        <th>Total :</th>
                        <th>EGP {grandTotal * discount}</th>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default EcommerceCart
