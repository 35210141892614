import React from 'react'
import { Col } from 'reactstrap'

const CarouselPage = () => {
  return (
    <Col xl={9}>
      <div className='auth-full-bg pt-lg-5 p-4'>
        <div className='w-100'>
          <div className='bg-overlay' />
          <div className='d-flex h-100 flex-column'>
            <div className='p-4 mt-auto'>
              <div className='row justify-content-center'>
                <div className='col-lg-7'>
                  <div className='text-center'>
                    <h4 className='mb-3'>
                      <span className='text-primary'>S</span>uplyd
                    </h4>
                    <div dir='ltr'>
                      <div className='item'>
                        <div className='py-3'>
                          <p className='font-size-16 mb-4'>
                            A company founded in December 2021 with the mission
                            of optimizing the supply chain of Egypt&apos;s
                            rapidly growing F&B sector through the provision of
                            a comprehensive digital solution that bridges the
                            gap between restaurant operators and their suppliers
                            in order to disrupt an untouched market with extreme
                            fragmentation and resolve the problem end-to-end.
                          </p>

                          <div>
                            <h4 className='font-size-16 text-primary'>
                              A One Stop Shop for F&B Supplies
                            </h4>
                            <p className='font-size-14 mb-0'>
                              Disrupting restaurants’ supply chain
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Col>
  )
}
export default CarouselPage
