import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useObjectVal, useList } from 'react-firebase-hooks/database'
import { ref } from 'firebase/database'
import MetaTags from 'react-meta-tags'
import { db } from 'utils/helpers/firebase_helper'

import {
  Container,
  Row,
  Col,
  Input,
  TabContent,
  TabPane,
  Card,
  Label,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button
} from 'reactstrap'
import { Link } from 'react-router-dom'

// Import Breadcrumb
import Breadcrumbs from 'components/Common/Breadcrumb'

// Import Images
import SideNav from 'components/SideNav'
import Loader from 'components/Loader'
import CheckoutList from 'components/CheckoutList'
import { addNewOrder } from 'store/actions'

const Checkout = () => {
  const dispatch = useDispatch()
  const [paymentMethod] = useState('CashOnDelivery')

  const [activeTab, setActiveTab] = useState('1')

  const { user } = useSelector((state) => ({
    user: state.Login.user
  }))

  const { uid } = user
  const [customer, loadingCustomer] = useObjectVal(ref(db, `customers/${uid}`))
  const [cartItemSnapshots, loadingCart] = useList(ref(db, `cart/${uid}`))

  const handleConfirmOrder = () => {
    dispatch(
      addNewOrder({
        customer,
        paymentMethod,
        cart: cartItemSnapshots.map((snap) => ({
          id: snap.key,
          ...snap.val()
        }))
      })
    )
  }

  if (loadingCustomer || loadingCart) {
    return <Loader />
  }

  const { branches, displayName } = customer
  const { shipping } = branches[branches.length - 1]

  return (
    <div className='page-content'>
      <MetaTags>
        <title>Checkout | Suplyd - F&B One Stop Shop</title>
      </MetaTags>
      <Container fluid>
        <Breadcrumbs title='Customer' breadcrumbItem='Checkout' />

        <div className='checkout-tabs'>
          <Row>
            <Col xl='2' sm='3'>
              <SideNav activeTab={activeTab} onTabSelect={setActiveTab} />
            </Col>
            <Col xl='10' sm='9'>
              <Card>
                <CardBody>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId='1'>
                      <div>
                        <CardTitle>Shipping information</CardTitle>
                        <CardSubtitle className='mb-3'>
                          Products will be delivered according to the
                          information below
                        </CardSubtitle>
                        <h5 className='font-size-14 text-primary mb-0'>
                          {displayName}
                        </h5>
                        <p className='mb-0'>{shipping.attention}</p>
                        <p className='mb-0'>{shipping.phone}</p>
                        <p className='mb-0'>{shipping.address}</p>
                        <p className='mb-0'>
                          {shipping.city}, {shipping.country}
                        </p>
                      </div>
                    </TabPane>
                    <TabPane
                      tabId='2'
                      id='v-pills-payment'
                      role='tabpanel'
                      aria-labelledby='v-pills-payment-tab'
                    >
                      <div>
                        <CardTitle>Payment information</CardTitle>
                        <CardSubtitle className='mb-4'>
                          Your payment method is set in your account settings,
                          to change that please contact our support
                        </CardSubtitle>
                        <div>
                          <div className='form-check form-check-inline font-size-16'>
                            <Input
                              type='radio'
                              value='1'
                              id='customRadioInline1'
                              name='customRadioInline1'
                              className='form-check-input'
                              disabled
                            />
                            <Label
                              className='form-check-label font-size-13'
                              htmlFor='customRadioInline1'
                            >
                              <i className='fab fa-cc-mastercard me-1 font-size-20 align-top' />{' '}
                              Credit / Debit Card
                            </Label>
                          </div>
                          <div className='form-check form-check-inline font-size-16'>
                            <Input
                              type='radio'
                              value='2'
                              id='customRadioInline2'
                              name='customRadioInline1'
                              className='form-check-input'
                              disabled
                            />
                            <Label
                              className='form-check-label font-size-13'
                              htmlFor='customRadioInline2'
                            >
                              <i className='fas fa-coins me-1 font-size-20 align-top' />{' '}
                              Credit Balance
                            </Label>
                          </div>
                          <div className='form-check form-check-inline font-size-16'>
                            <Input
                              type='radio'
                              value='3'
                              id='customRadioInline3'
                              defaultChecked
                              name='customRadioInline1'
                              className='form-check-input'
                            />
                            <Label
                              className='form-check-label font-size-13'
                              htmlFor='customRadioInline3'
                            >
                              <i className='far fa-money-bill-alt me-1 font-size-20 align-top' />{' '}
                              Cash on Delivery
                            </Label>
                          </div>
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId='3' id='v-pills-confir' role='tabpanel'>
                      <Card className='shadow-none border mb-0'>
                        <CardBody>
                          <CardTitle className='mb-4'>Order Summary</CardTitle>
                          <CheckoutList
                            products={cartItemSnapshots.map((snap) => ({
                              id: snap.key,
                              ...snap.val()
                            }))}
                          />
                        </CardBody>
                      </Card>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
              <Row className='mt-4'>
                <Col sm='6'>
                  <Link
                    to='/cart'
                    className='btn text-muted d-none d-sm-inline-block btn-link'
                  >
                    <i className='mdi mdi-arrow-left me-1' /> Back to Shopping
                    Cart{' '}
                  </Link>
                </Col>
                <Col sm='6'>
                  <div className='text-sm-end'>
                    <Button
                      onClick={() => {
                        handleConfirmOrder()
                      }}
                      className='btn btn-success'
                    >
                      <i className='mdi mdi-truck-fast me-1' /> Confirm Order
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default Checkout
