import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import SimpleBar from 'simplebar-react'
import MetisMenu from 'metismenujs'
import { withRouter, Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

const SidebarContent = ({ location, t }) => {
  const ref = useRef()

  const scrollElement = (item) => {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  const activateParentDropdown = (item) => {
    item.classList.add('active')
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show')
    }

    if (parent) {
      parent.classList.add('mm-active')
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add('mm-show') // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add('mm-active') // li
          parent3.childNodes[0].classList.add('mm-active') // a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add('mm-show') // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add('mm-show') // li
              parent5.childNodes[0].classList.add('mm-active') // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  useEffect(() => {
    const pathName = location.pathname

    const initMenu = () => {
      // eslint-disable-next-line no-new
      new MetisMenu('#side-menu')
      const ul = document.getElementById('side-menu')
      const items = [...ul.getElementsByTagName('a')]
      const matchingMenuItem = items.find((item) => pathName === item.pathname)
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  return (
    <SimpleBar className='h-100' ref={ref}>
      <div id='sidebar-menu'>
        <ul className='metismenu list-unstyled' id='side-menu'>
          <li className='menu-title'>{t('Menu')} </li>
          <li>
            <Link to='/dashboard' className=''>
              <i className='bx bx-home-circle' />
              <span>{t('Dashboard')}</span>
            </Link>
          </li>

          <li>
            <Link to='/my-list' className=''>
              <i className='bx bx-store' />
              <span>{t('My List')}</span>
            </Link>
          </li>

          <li>
            <Link to='/orders'>
              <i className='bx bx-receipt' />
              <span>{t('Orders')}</span>
            </Link>
          </li>
        </ul>
      </div>
    </SimpleBar>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
}

export default withRouter(withTranslation()(SidebarContent))
