import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'reactstrap'
import CartListItem from './CartListItem'

const CartList = ({ products }) => {
  return (
    <div className='table-responsive'>
      <Table className='table table-sm align-middle mb-0 table-nowrap'>
        <thead className='table-light'>
          <tr>
            <th>Product Desc</th>
            <th>Price</th>
            <th>Quantity</th>
            <th colSpan='2'>Total</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product) => (
            <CartListItem
              key={`__cartListItem_${product.id}`}
              product={product}
            />
          ))}
        </tbody>
      </Table>
    </div>
  )
}

CartList.propTypes = {
  products: PropTypes.array.isRequired
}

export default CartList
