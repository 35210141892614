import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const CheckoutListItem = ({ product }) => {
  return (
    <tr>
      <th scope='row'>
        <img
          src={product.img}
          alt='product-img'
          title='product-img'
          className='avatar-md'
        />
      </th>
      <td>
        <h5 className='font-size-14 text-truncate'>
          <Link to='/product-detail' className='text-dark'>
            {product.name}
          </Link>
        </h5>
        <p className='text-muted mb-0'>
          EGP {product.price} x {product.quantity}
        </p>
      </td>
      <td>EGP {product.price * product.quantity}</td>
    </tr>
  )
}

CheckoutListItem.propTypes = {
  product: PropTypes.object.isRequired
}
export default CheckoutListItem
